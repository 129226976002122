(function( $ ) {

    $.fn.newsHeadingSlider = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var sliderContainer = jqContainer.find('.news-headings-slider-holder');
            if (!sliderContainer || sliderContainer === undefined) {
                console.error('newsHeadingSlider: no news-headings-slider-holder fround in', this);
            }
            var prev = jqContainer.find('.prev');
            var next = jqContainer.find('.next');
            var nav = jqContainer.find('.nav');

            sliderContainer.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                infinite: false,
                prevArrow: prev,
                nextArrow: next,
                appendDots: nav,
                autoplay: false,
                adaptiveHeight: true,
                vertical: true,
                verticalSwiping: true,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {

                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {

                        }
                    },
                ]
            });

        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {

    $('.news-headings-slider').newsHeadingSlider();

});
