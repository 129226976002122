import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import newsHeadingSliderJS from './includes/news_headings_slider.js';
import eventSliderJS from './includes/event_slider.js';
import clubSliderJS from './includes/club_slider.js';
import mainBlockIMG from '../img/main-block.jpg';
import banner1IMG from '../img/banner-1.png';
import banner2IMG from '../img/banner-2.png';
import banner3IMG from '../img/banner-3.png';
import banner3MdIMG from '../img/banner-3-md.png';
import banner3XsIMG from '../img/banner-3-xs.png';
import newsPreview1IMG from '../img/news-preview-1.jpg';
import newsPreview2IMG from '../img/news-preview-2.jpg';
import newsPreview3IMG from '../img/news-preview-3.jpg';
import newsPreview4IMG from '../img/news-preview-4.jpg';
import clubLogo1IMG from '../img/club-logo-1.png';
import clubLogo2IMG from '../img/club-logo-2.png';
import clubLogo3IMG from '../img/club-logo-3.png';
import clubLogo4IMG from '../img/club-logo-4.png';
import clubLogo5IMG from '../img/club-logo-5.png';
import clubLogo6IMG from '../img/club-logo-6.png';
import clubPreview1IMG from '../img/club-preview-1.jpg';
import clubPreview2IMG from '../img/club-preview-2.jpg';
import clubPreview3IMG from '../img/club-preview-3.jpg';
import clubPreview4IMG from '../img/club-preview-4.jpg';
import clubPreview5IMG from '../img/club-preview-5.png';
import clubPreview6IMG from '../img/club-preview-6.jpg';
import weatherIMG from '../img/weather.png';
import catalogItem1IMG from '../img/catalog-item-1.png';
import catalogItem2IMG from '../img/catalog-item-2.png';
import catalogItem3IMG from '../img/catalog-item-3.png';
import catalogItem4IMG from '../img/catalog-item-4.png';
import bigNews1IMG from '../img/big-news-1.jpg';
import bigNews2IMG from '../img/big-news-2.png';
import seminar1IMG from '../img/seminar-1.png';
import seminar2IMG from '../img/seminar-2.png';
import seminar3IMG from '../img/seminar-3.png';
import seminar4IMG from '../img/seminar-4.png';
import club1IMG from '../img/club-1.png';
import club2IMG from '../img/club-2.png';
import club3IMG from '../img/club-3.png';
import club4IMG from '../img/club-4.png';
import club5IMG from '../img/club-5.png';
import club6IMG from '../img/club-6.png';
import club7IMG from '../img/club-7.png';
import newsSlider1IMG from '../img/news-slider-1.jpg';
import newsSlider2IMG from '../img/news-slider-2.jpg';
import newsSlider3IMG from '../img/news-slider-3.jpg';
import socialImages1IMG from '../img/social-images-1.png';
import socialImages2IMG from '../img/social-images-2.png';
import socialImages3IMG from '../img/social-images-3.png';
import socialImages4IMG from '../img/social-images-4.png';
import socialImages5IMG from '../img/social-images-5.png';
import socialImages6IMG from '../img/social-images-6.png';
import socialImages7IMG from '../img/social-images-7.png';
import socialImages8IMG from '../img/social-images-8.png';
